<template>

    <div class="connexion">

        <div class="centreconnexion">
            <h1> Connexion </h1>
            <div class="blocconnexion">
                <form method="POST"> 
                    <div class="formemail">              
                        <label> Email </label>
                        <div class="inputemail">
                            <input type="email" placeholder="Entrer votre adresse e-mail" name="email" style="width:338px; height:44px;border-radius: 8px; border: none; text-indent: 20px;" required>
                        </div>
                    </div>

                    <div class="formmdp">
                        <label> Mot de passe </label>
                        <div class="inputmotdepasse">
                            <input type="password" placeholder="Entrez votre mot de passe" name="motdepasse" style="width:338px; height:44px; border-radius: 8px; border: none; text-indent: 20px;" required>
                        </div>
                    </div>

                    <p> Mot de passe oublié ? </p>
                    <input type="submit" id='submit' value='Connexion' >
                </form>
            </div>
        </div>

    </div>
    
</template>

<script>

    export default {
        
        name: 'connexion',

    }

</script>

<style scoped>

    .connexion {
        
        height: 100vh;
        background-color: #F0F2FA;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .connexion h1 {

        font-size: 32px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 50px;

    }

    .blocconnexion {

        height: 418px;
        width: 408px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;

    }

    .blocconnexion p {

        color: #5887FF;
        padding-bottom: 50px;
        font-weight: bold;

    }

    .blocconnexion form {

        padding-top: 50px;

    }

    .formemail label{

        font-size: 16px;
        color: #2c3e50;
        font-weight: bold;

    }

    .inputemail {

        padding-bottom: 20px;
        padding-top: 10px;

    }

    .inputemail input {

        background-color: #F0F2FA;

    }

    .inputmotdepasse {

        padding-bottom: 25px;
        padding-top: 10px;

    }

    .inputmotdepasse input {

        background-color: #F0F2FA;

    }

    input::placeholder {
        
        color: #D6D7E3;

    }

    .formmdp label{

        font-size: 16px;
        color: #2c3e50;
        font-weight: bold;

    }

    #submit {

        height: 44px;
        width: 338px;
        border-radius: 4px;
        background-color: #2c3e50;
        color: white; 
        border: none; 
        font-size: 16px;

    }

</style>